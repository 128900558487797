class Tabs {
  tabSections = "tabs";
  tabButton = "tabs-button";
  tabButtonActive = "active";
  tabButtonDisabled = "hidden";

  constructor() {
    this.init();
  }

  init = () => {
    if (document.querySelector(`.${this.tabSections}`)) {
      document.querySelectorAll(`.${this.tabSections}`).forEach((section) => {
        const tabButtons = section.querySelectorAll(`.${this.tabButton}`);
        if (tabButtons) {
          tabButtons.forEach((button) => {
            const targetClass = button.dataset.target;
            const targetTab = section.querySelector(targetClass);

            button.addEventListener("click", (e) => {
              let activeButton = section.querySelector(`.${this.tabButton}.${this.tabButtonActive}`);
              let activeTab = section.querySelector(activeButton.dataset.target);

              activeButton.classList.remove(`${this.tabButtonActive}`);
              activeTab.classList.add(`${this.tabButtonDisabled}`);

              button.classList.add(`${this.tabButtonActive}`);
              targetTab.classList.remove(`${this.tabButtonDisabled}`);
            });
          });
        }
      });
    }
  };
}

export default Tabs;
