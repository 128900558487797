import Swiper, { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import Splide from "@splidejs/splide";
import "@splidejs/splide/css";

export const offerSlider = () => {
  let sliders = document.querySelectorAll(".offer-elements");
  if (sliders)
    sliders.forEach((el) => {
      const slider = el.querySelector(".offer-slider");
      const swiper = new Swiper(slider, {
        modules: [Navigation],
        autoplay: {
          delay: 3000,
        },
        spaceBetween: 10,
        slidesPerGroup: 1,
        slidesPerView: 1,
        navigation: {
          nextEl: el.querySelector(".swiper-button-next"),
          prevEl: el.querySelector(".swiper-button-prev"),
        },
        breakpoints: {
          320: {
            slidesPerGroup: 1,
            slidesPerView: 1,

            spaceBetween: 16,
            // centeredSlides: true,
          },
          576: {
            slidesPerGroup: 1,
            slidesPerView: 2,
          },
          992: {
            slidesPerGroup: 1,
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1366: {
            slidesPerGroup: 1,
            slidesPerView: 4,
          },
          1440: {
            slidesPerGroup: 1,
            slidesPerView: 5,
          },
          1671: {
            slidesPerGroup: 1,
            slidesPerView: 6,
          },
        },
      });
    });
};
export const postsSlider = () => {
  let sliders = document.querySelectorAll(".blog-elements");

  if (sliders)
    sliders.forEach((el) => {
      const slider = el.querySelector(".blog-slider");
      const swiper = new Swiper(slider, {
        modules: [Navigation],
        autoplay: {
          delay: 3000,
        },
        spaceBetween: 20,
        slidesPerGroup: 1,
        slidesPerView: 4,
        navigation: {
          nextEl: el.querySelector(".swiper-button-next"),
          prevEl: el.querySelector(".swiper-button-prev"),
        },
        breakpoints: {
          320: {
            slidesPerGroup: 1,
            slidesPerView: 1.2,

            spaceBetween: 16,
            // centeredSlides: true,
          },
          576: {
            slidesPerGroup: 1,
            slidesPerView: 2,
          },
          992: {
            slidesPerGroup: 1,
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1367: {
            slidesPerGroup: 1,
            slidesPerView: 4,
          },
        },
      });
    });
};

export const scheduleSlider = () => {
  let sliders = document.querySelectorAll(".group-schedule");
  if (sliders)
    sliders.forEach((el) => {
      const slider = el.querySelector(".slider-schedule");
      const swiper = new Swiper(slider, {
        modules: [Navigation],
        autoplay: {
          delay: 3000,
        },
        grabCursor: true,
        spaceBetween: 0,
        slidesPerGroup: 1,
        slidesPerView: 3,
        navigation: {
          nextEl: el.querySelector(".swiper-button-next"),
          prevEl: el.querySelector(".swiper-button-prev"),
        },
        breakpoints: {
          320: {
            slidesPerGroup: 1,
            slidesPerView: 1,
          },
          360: {
            slidesPerGroup: 2,
            slidesPerView: 2,
          },
          576: {
            slidesPerGroup: 1,
            slidesPerView: 2,
          },
          992: {
            slidesPerGroup: 1,
            slidesPerView: 3,
          },
          1367: {
            slidesPerGroup: 1,
            slidesPerView: 6,
          },
        },
      });
    });
};

export const termsSlider = () => {
  const slider = document.querySelector(".terms-slider");

  if (slider) {
    const activeTerm = slider.querySelector(".splide__slide.active-term");
    const activeIndex = [...activeTerm.parentNode.children].indexOf(activeTerm);
    const slides = slider.querySelectorAll(".splide__slide");
    const gap = 30;
    let slidesTotalWidth = 0;
    const track = slider.querySelector(".splide__list");
    slides.forEach((el) => {
      slidesTotalWidth += el.clientWidth + gap;
    });
    var splider = new Splide(slider, {
      autoWidth: true,
      gap: gap,
      pagination: false,
      arrows: track.offsetWidth - slidesTotalWidth < 0 ? true : false,
      perMove: 5,
      wheel: true,
      breakpoints: {
        991: {
          perMove: 1,
        },
      },
    });
    splider.on("mounted", function () {
      slider.classList.remove("opacity-0");
    });
    splider.mount();
    splider.go(activeIndex);
  }
};

export const personalSchedule = () => {
  const slider = document.querySelector(".single-schedule");
  if (slider) {
    const splider = new Splide(slider, {
      perMove: 1,
      perPage: 1,
      pagination: false,
    });
    splider.mount();
  }
};
