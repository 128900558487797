class GoogleMap {
  styles;
  args;
  mapData;
  markerData;
  mapWrapper = "g-map";

  constructor() {
    if (document.querySelector(`.${this.mapWrapper}`)) {
      this.styles = JSON.parse(map_global_vars.map_styles);
      this.args = JSON.parse(map_global_vars.map_settings);
      this.mapData = JSON.parse(map_global_vars.map_data);
      this.markerData = JSON.parse(map_global_vars.marker_data);
      this.init();
    }
  }

  loadMap = (map) => {
    if (map.classList.contains("loaded")) {
      return true;
    }

    const googleMap = new google.maps.Map(map, {
      zoom: window.innerWidth < 765 ? 11 : this.args.zoomLevel,
      gestureHandling: window.innerWidth < 765 ? "cooperative" : "cooperative",
      center: {
        lat: window.innerWidth < 765 ? this.markerData.lat : this.mapData.lat,
        lng: window.innerWidth < 765 ? this.markerData.lng : this.mapData.lng,
      },
      zoomControl: this.args.zoomControl,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
      mapTypeControl: this.args.mapTypeControl,
      streetViewControl: this.args.streetViewControl,
      rotateControl: this.args.rotateControl,
      rotateControlOptions: this.args.rotateControlOptions,
      scaleControl: this.args.scaleControl,
      fullscreenControl: this.args.fullscreenControl,
      styles: this.styles,
    });
    let googleMarker = new google.maps.Marker({
      position: {
        lat: this.markerData.lat,
        lng: this.markerData.lng,
      },
      map: googleMap,
      icon: map_global_vars.map_pin,
      url: this.markerData.url,
      title: this.markerData.title,
    });
    google.maps.event.addListener(googleMarker, "click", function () {
      // window.location.href = this.url;
      window.open(this.url, "_blank");
    });
    map.classList.add("loaded");
  };

  init = () => {
    const mapContainers = document.querySelectorAll(`.${this.mapWrapper}`);

    mapContainers.forEach((mc) => {
      const onIntersection = (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            this.loadMap(mc);
          }
        }
      };
      const observer = new IntersectionObserver(onIntersection, {
        threshold: 0.5,
      });

      observer.observe(mc);
    });
  };
}

export default GoogleMap;
