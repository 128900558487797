import { toggleScroll } from "./_helpers";

class Menu {
  wrapper = ".burger";
  triggerClass = ".bun";
  activeClass = "active";
  navigation = ".header-navigation";
  navToggleClass = "lg:translate-x-full";
  wrapperButton = document.querySelector(this.wrapper);
  bg = ".mobile-bg";
  bgClose = document.querySelector(this.bg);

  init = () => {
    this.wrapperButton.querySelector(this.triggerClass).addEventListener(
      "click",
      (e) => {
        if (this.wrapperButton.classList.contains(this.activeClass)) {
          this.close();
        } else {
          this.open();
        }
      },
      false
    );

    this.bgClose.addEventListener(
      "click",
      (e) => {
        this.close();
      },
      false
    );
  };

  open = () => {
    this.wrapperButton.classList.add(this.activeClass);
    this.wrapperButton.ariaExpanded = "true";
    document.querySelector(this.navigation).classList.remove(this.navToggleClass);
    toggleScroll();
    this.bgClose.classList.add(this.activeClass);
  };

  close = () => {
    this.wrapperButton.classList.remove(this.activeClass);
    this.wrapperButton.ariaExpanded = "false";
    document.querySelector(this.navigation).classList.add(this.navToggleClass);
    toggleScroll();
    this.bgClose.classList.remove(this.activeClass);
  };
}

export default Menu;
