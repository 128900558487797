import { nodeChildIndex } from "./_helpers";

class Accordion {
  init = () => {
    const accordion = document.querySelectorAll(".accordion");

    accordion.forEach((acc) => {
      const groups = acc.querySelectorAll(".accordion-group");

      const triggers = acc.querySelectorAll("label.accordion-header input");
      triggers.forEach((trigger) => {
        trigger.addEventListener("click", (e) => {
          trigger.parentNode.parentNode.querySelector(".accordion-content").classList.toggle("hidden");
        });
      });

      groups.forEach((gr) => {
        const tds = gr.querySelectorAll("td.hoverable");

        tds.forEach((td) => {
          td.addEventListener("mouseenter", (e) => {
            const trs = gr.querySelectorAll("tr.hoverable");
            const hoverIndex = nodeChildIndex(td);
            trs.forEach((light) => {
              light.children[hoverIndex].classList.add("bg-gray-50");
            });
          });

          td.addEventListener("mouseleave", (e) => {
            const trs = gr.querySelectorAll("tr.hoverable");
            const hoverIndex = nodeChildIndex(td);
            trs.forEach((light) => {
              light.children[hoverIndex].classList.remove("bg-gray-50");
            });
          });
        });
      });
    });
  };
}

export default Accordion;
