import { enableScroll, disableScroll } from "./_helpers";

class Modal {
  openModalButton = "open-modal";
  closeModalButton = "close-modal";
  toggleClass = "hidden";
  modals;
  contentWrapper = "modal-inner";

  constructor() {
    if (document.querySelector(`.${this.openModalButton}`)) {
      this.modals = document.querySelectorAll(`.${this.openModalButton}`);
      this.init();
    }
  }

  init = () => {
    this.modals.forEach((modal_button) => {
      const target = modal_button.dataset.target;
      const modal_window = document.querySelector(target);
      const close_modal = modal_window.querySelector(`.${this.closeModalButton}`);

      close_modal.addEventListener("click", (e) => {
        modal_window.classList.add(`${this.toggleClass}`);
        enableScroll();
      });

      modal_button.addEventListener("click", (e) => {
        document.querySelector(target).classList.remove(`${this.toggleClass}`);
        disableScroll();
      });

      modal_window.addEventListener("click", (e) => {
        modal_window.classList.add(`${this.toggleClass}`);
        enableScroll();
      });

      modal_window.querySelector(`.${this.contentWrapper}`).addEventListener("click", (e) => {
        e.stopPropagation();
      });
    });
  };
}

export default Modal;
