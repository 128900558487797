export const cookieSet = (key, value, location, days) => {
  var expires = "",
    path = "";
  if (typeof days != "undefined" && days != "") {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  }
  if (typeof location != "undefined" && location != "") {
    path = "; path=" + location;
  }
  document.cookie = key + "=" + escape(value) + expires + path;
};

export const cookieGet = (key) => {
  var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
  return keyValue ? keyValue[2] : null;
};

export const unwrap = (wrapper) => {
  // place childNodes in document fragment
  var docFrag = document.createDocumentFragment();
  while (wrapper.firstChild) {
    var child = wrapper.removeChild(wrapper.firstChild);
    docFrag.appendChild(child);
  }

  // replace wrapper with document fragment
  wrapper.parentNode.replaceChild(docFrag, wrapper);
};

export const disableScroll = () => {
  document.body.style.overflowY = "hidden";
  document.body.classList.add("scroll-locked");
  var x = window.scrollX;
  var y = window.scrollY;
  window.scrollTo(x, y);
  window.onscroll = function () {
    window.scrollTo(x, y);
  };
};

export const enableScroll = () => {
  document.body.style.overflow = "";
  document.body.classList.remove("scroll-locked");
  window.onscroll = function () {};
};

export const toggleScroll = () => {
  if (document.body.classList.contains("scroll-locked")) {
    enableScroll();
    document.body.classList.remove("scroll-locked");
  } else {
    disableScroll();
    document.body.classList.add("scroll-locked");
  }
};
export const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
};

export const parseTransform = (transform) => {
  var prop = ["translate", "matrix", "rotate", "skewX", "skewY", "scale"];
  var val = transform.match(/(translate|matrix|rotate|skewX|skewY|scale)\(.*?\)/g);
  var obj = {};
  if (val) {
    for (var i = 0, length = val.length; i < length; i++) {
      var item = val[i];
      var index = item.indexOf("(");
      var v = item.substring(index + 1, item.length - 1).split(/\,|\s/);
      var n = item.substring(0, index);
      obj[n] = {};
      switch (n) {
        case "translate":
        case "scale":
          obj[n].x = +v[0] || 0;
          obj[n].y = +v[1] || 0;
          break;
        case "rotate":
          obj[n].a = +v[0] || 0;
          obj[n].x = +v[1] || 0;
          obj[n].y = +v[2] || 0;
          break;
        case "skewX":
        case "skewY":
          obj[n].a = +v[0];
          break;
        case "matrix":
          obj[n].a = +v[0] || 0;
          obj[n].b = +v[1] || 0;
          obj[n].c = +v[2] || 0;
          obj[n].d = +v[3] || 0;
          obj[n].e = +v[4] || 0;
          obj[n].f = +v[5] || 0;
          break;
      }
    }
  }

  obj.toString = function () {
    var builder = [];
    for (var i = 0, length = prop.length; i < length; i++) {
      var n = prop[i];
      var o = this[n];
      if (!o) continue;
      switch (n) {
        case "translate":
        case "scale":
          builder.push(n + "(" + o.x + "," + o.y + ")");
          break;
        case "rotate":
          builder.push(n + "(" + o.a + " " + o.x + " " + o.y + ")");
          break;
        case "skewX":
        case "skewY":
          builder.push(n + "(" + o.a + ")");
          break;
        case "matrix":
          builder.push(n + "(" + o.a + "," + o.b + "," + o.c + "," + o.d + "," + o.e + "," + o.f + ")");
          break;
      }
    }
    return builder.join(" ");
  };

  return obj;
};

export const randomBetween = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const ConvertToVTT = (extension, input) => {
  var rawLines = [];
  var processedLines = [];
  var cue = 0;
  var i;
  var j;
  var line;
  rawLines = input.replace(/\r?\n|\r|\n/g, "\r\n").split("\r\n");
  switch (extension) {
    case "ass":
    case "ssa":
      var identifier = [];
      var lineArray = [];
      for (i = 0; i < rawLines.length; i++) {
        if (rawLines[i].toUpperCase().indexOf("[EVENTS]") > -1) {
          rawLines.splice(0, i + 1);
          break;
        }
      }
      for (i = 0; i < rawLines.length; i++) {
        line = [];
        if (rawLines[i].trim().length > 0) {
          lineArray = [];
          lineArray[0] = rawLines[i].split(": ");
          lineArray[1] = lineArray[0][1].split(",");
          line[0] = lineArray[0][0];
          line = line.concat(lineArray[1]);
          if (line[0].toUpperCase().indexOf("FORMAT") > -1) {
            for (j = 1; j < line.length; j++) {
              if (line[j].toUpperCase().indexOf("START") > -1) {
                identifier[0] = j;
                continue;
              }
              if (line[j].toUpperCase().indexOf("END") > -1) {
                identifier[1] = j;
                continue;
              }
              if (line[j].toUpperCase().indexOf("TEXT") > -1) {
                identifier[2] = j;
                continue;
              }
            }
          } else {
            processedLines[cue] = [];
            processedLines[cue++] = [line[identifier[0]], line[identifier[1]], line[identifier[2]]];
          }
        }
      }
      break;
    case "srt":
      for (i = 0; i < rawLines.length; i++) {
        line = [];
        if (rawLines[i] === cue + 1) {
          continue;
        } else if (rawLines[i].trim().length > 1) {
          if (rawLines[i].indexOf(" --> ") > -1) {
            line = rawLines[i].split(" --> ");
            processedLines[cue] = [];
            processedLines[cue][0] = line[0].replace(",", ".");
            processedLines[cue][1] = line[1].replace(",", ".");
            processedLines[cue][2] = "";
          } else {
            if (processedLines[cue][2].length > 0) {
              processedLines[cue][2] += "\r\n";
            }
            processedLines[cue][2] += rawLines[i];
          }
        } else if (processedLines[cue] !== undefined) {
          cue++;
        }
        break;
      }
  }
  if (cue > 0) {
    var output = "WEBVTT\r\n";
    var timing;
    for (i = 0; i < cue; i++) {
      output += "\r\n" + (i + 1) + "\r\n";
      timing = [];
      for (j = 0; j < 2; j++) {
        timing[j] = new Date("0000-01-01T" + ("0" + processedLines[i][j]).slice(-12) + "Z");
        processedLines[i][j] = ("0" + timing[j].getUTCHours()).slice(-2) + ":" + ("0" + timing[j].getUTCMinutes()).slice(-2) + ":" + ("0" + timing[j].getUTCSeconds()).slice(-2) + "." + ("00" + timing[j].getUTCMilliseconds()).slice(-3);
      }
      output += processedLines[i][0] + " --> " + processedLines[i][1] + "\r\n" + processedLines[i][2] + "\r\n";
    }
    return output;
  } else {
    return undefined;
  }
};
export const orientation = () => {
  if (window.matchMedia("(orientation: portrait)").matches) {
    return "portrait";
  } else {
    return "landscape";
  }
};

export const nodeIndex = (node) => {
  return Array.prototype.indexOf.call(node.parentNode.childNodes, node);
};

export const nodeChildIndex = (node) => {
  return Array.prototype.indexOf.call(node.parentNode.children, node);
};
