import { domReady } from "@roots/sage/client";
import lazyLoadImages from "./components/lazyLoad";
import GoogleMap from "./components/googleMap";
import Tabs from "./components/tabs";
import Modal from "./components/modal";
import Blob from "./components/blob";
import { offerSlider, postsSlider, scheduleSlider, termsSlider, personalSchedule } from "./components/sliders.js";
import Menu from "./components/menu";
import Accordion from "./components/accordion";
import AOS from "aos";
import "aos/dist/aos.css";

const main = async (err) => {
  if (err) {
    console.error(err);
  }
  lazyLoadImages();
  new Blob();
  offerSlider();
  postsSlider();
  scheduleSlider();
  termsSlider();
  new GoogleMap();
  new Tabs();
  new Modal();
  personalSchedule();
  new Menu().init();
  new Accordion().init();
  AOS.init({
    once: true,
  });
};

domReady(main);
